<template>
	<div class="inner">
		<img src="../assets/join-banner.png" width="100%" alt="" />
		<div class="container">
			<div class="all-title over mt50 pt20 pb16 el-border-bottom mb20">
				<div class="fl ft36 bold">联系我们</div>
				<div class="fl ft24 bl9 ml12 mt12"> / Contact Us</div>
			</div>
			<div class="tools-list tr3 pt30 hover pb24 over el-border-bottom-b" v-for="(item,index) in recruitList" :key="item.id" @click="handleDetails(item.id)">
				<div class="fl ft18 tr3">{{item.name}}</div>
				<div class="fr"><i class="el-icon-arrow-right tr3"></i></div>
			</div>
			<div class="tc pt50">
				<el-pagination background layout="prev, pager, next" :total="total"  :current-page="queryParams.pageNum" :page-size="queryParams.pageSize"  @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { recruitPageList } from '@/api/index.js'
	export default {
		data(){
			return{
				total: 0,
				recruitList: [],
				queryParams:{
					pageNum: 1,
					pageSize: 10
				}
			}
		},
		created(){
			this.getList()
		},
		methods:{
			getList(){
				recruitPageList(this.queryParams).then(res=>{
					if(res.code === 200){
						this.recruitList = res.data.list;
						this.total = res.data.count;
					}
				})
			},
			handleCurrentChange(val){
				this.queryParams.pageNum = val
				this.getList()
			},
			handleDetails(id){
				this.$router.push({
					path: '/join-details',
					query:{
						id: id
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.tools-list:hover{
		color: #b4232f;
		transform: translateX(4px);
	}
	.container{margin-bottom: 200px;}
	.el-border-bottom-b{border-bottom: 1px solid rgba(0,0,0,.03);}
</style>
